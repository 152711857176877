import React, { useState } from "react";
import "./AccordinSection.scss";
import plus from "assets/images/plus-svg.svg";
import minus from "assets/images/minus-svg.svg";

const AccordinSection = () => {
  // State to track which accordion items are open
  const [openIndices, setOpenIndices] = useState([0]);

  // Function to handle opening/closing of accordion items
  const toggleAccordion = (index) => {
    if (openIndices.includes(index)) {
      // If the index is already in the openIndices array, remove it (close the accordion)
      setOpenIndices(openIndices.filter((i) => i !== index));
    } else {
      // Otherwise, add it to the array (open the accordion)
      setOpenIndices([...openIndices, index]);
    }
  };

  // Sample questions and answers
  const faqs = [
    {
      question: "¿Cómo funciona el proceso de solicitud?",
      answer:
        "Al crear tu cuenta, podrás cotizar y solicitar el producto de tu interés al instante. Nosotros nos encargamos del resto.",
    },
    {
      question:
        "¿Cuáles son los productos específicos de financiamiento que ofrecen?",
      answer:
        "Ofrecemos tres productos principales diseñados para brindar soluciones flexibles y adaptadas a diferentes situaciones financieras:\n- Crédito de Margen.\n- Crédito Simple.\n- Factoraje.",
    },
    {
      question: "¿Cómo puedo monitorear mis productos activos?",
      answer:
        "Con el objetivo de brindar total transparencia, ofrecemos un acceso fácil y seguro a nuestra plataforma, donde puedes monitorear tus créditos activos, recibir notificaciones en tiempo real y acceder a documentación relevante.",
    },
    {
      question:
        "¿Cuál es el plazo típico para los productos de financiamiento que ofrecen?",
      answer:
        "El plazo varía según el tipo de financiamiento, pero nos esforzamos por adaptarnos a las necesidades de cada negocio. Durante el proceso de solicitud, te proporcionaremos detalles específicos sobre el plazo del producto de financiamiento que mejor se adapte a tus objetivos.",
    },
    {
      question:
        "¿Cómo se determinan las tasas de interés para los productos de financiamiento?",
      answer:
        "Las tasas de interés se determinan considerando diversos factores, como el tipo de financiamiento, el plazo y la situación financiera de la empresa. Proporcionamos información clara sobre las tasas antes de que confirmes cualquier solicitud.",
    },
    {
      question:
        "¿Cuál es el monto máximo que puedo solicitar en un producto de financiamiento?",
      answer:
        "El monto máximo varía según el tipo de financiamiento y la situación financiera de tu empresa. Durante el proceso de solicitud, te informaremos sobre el monto máximo disponible para tu caso específico.",
    },
    {
      question: "¿Qué es un crédito Back to Back?",
      answer:
        "Un crédito Back to Back es un tipo de financiamiento a corto-mediano plazo que les permite a los negocios obtener la liquidez que necesitan dejando como garan_a su cuenta de inversión.",
    },
    // Add more FAQs as needed
  ];

  return (
    <div className="w-full mt-[40px] mb-[45px] px-[30px] sm:px-[60px] xl:px-[0px] xl:w-[1040px]">
      <div className="accordinHeader mb-5">
        Contestamos tus preguntas sobre Ave
      </div>
      {faqs.map((faq, index) => (
        <div key={index} className="accordinContainer w-full mb-5 border-b">
          <div
            className="accordin px-12 py-6 flex flex-col cursor-pointer"
            onClick={() => toggleAccordion(index)}
          >
            <div className="w-full flex flex-row">
              <div className="textTitle w-full text-start">{faq.question}</div>
              <img
                src={openIndices.includes(index) ? minus : plus}
                width="20"
                alt="toggle icon"
              />
            </div>
            {openIndices.includes(index) && (
              <div className="w-11/12 py-2 text-left">
                <div className="textDescription">{faq.answer}</div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AccordinSection;
