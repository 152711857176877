import { useState, useEffect } from "react";

export const validDigits = (n) => {
  return n.replace(/[^0-9.]+/g, "");
};

export const isValidEmail = (email) => {
  // Use a regular expression to validate the email format
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return emailRegex.test(email);
};

export const limit = (string = "", limit = 0) => {
  return string.substring(0, limit);
};

export const useViewport = () => {
  const [viewport, setViewport] = useState("lg");

  useEffect(() => {
    if (window.innerWidth > 1536) {
      setViewport("2xl");
    } else if (window.innerWidth > 1280 && window.innerWidth <= 1536) {
      setViewport("xl");
    } else if (window.innerWidth > 1024 && window.innerWidth <= 1280) {
      setViewport("lg");
    } else if (window.innerWidth > 768 && window.innerWidth <= 1024) {
      setViewport("md");
    } else if (window.innerWidth >= 640 && window.innerWidth <= 768) {
      setViewport("sm");
    } else {
      setViewport("xs");
    }
  }, []);

  useEffect(() => {
    const resizeFunction = () => {
      if (window.innerWidth > 1536) {
        setViewport("2xl");
      } else if (window.innerWidth > 1280 && window.innerWidth <= 1536) {
        setViewport("xl");
      } else if (window.innerWidth > 1024 && window.innerWidth <= 1280) {
        setViewport("lg");
      } else if (window.innerWidth > 768 && window.innerWidth <= 1024) {
        setViewport("md");
      } else if (window.innerWidth >= 640 && window.innerWidth <= 768) {
        setViewport("sm");
      } else {
        setViewport("xs");
      }
    };
    window.addEventListener("resize", resizeFunction);
  }, []);
  return viewport;
};

export const validatePassword = (password) => {
  const errors = [];
  const passwordRules = [
    { regex: /.{8,}/, message: "At least 8 characters long" },
    // { regex: /[A-Z]/, message: "At least one uppercase letter" },
    // { regex: /[a-z]/, message: "At least one lowercase letter" },
    // { regex: /[0-9]/, message: "At least one number" },
    // { regex: /[^A-Za-z0-9]/, message: "At least one special character" },
  ];

  passwordRules.forEach((rule) => {
    if (!rule.regex.test(password)) {
      errors.push(rule.message);
    }
  });

  return errors;
};
