import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { thunk } from "redux-thunk";
import authReducer from "./reducers/authReducer";
import authMiddleware from "../middlewares/authMiddleware";
import landingReducer from "./reducers/landingReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  landing: landingReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk, authMiddleware))
);

export default store;
