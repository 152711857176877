// project imports
import MainLayout from "layout/MainLayout";
import { useEffect } from "react";

// Main Pages
import LandingPage from "../pages/landing/MainSection/MainSection";
import Signup from "pages/auth/signup/Signup";
import Signin from "pages/auth/signin/Signin";
import AnalyticSection from "pages/landing/AnalyticSection/AnalyticSection";
import Privacy from "pages/landing/Privacidad/Privacidad";
import TermsSection from "pages/landing/Terms/Terms";

// project imports
import { Navigate } from "react-router-dom";


// ExternalRedirect component to handle redirects to external URLs
const ExternalRedirect = ({ url }) => {
  useEffect(() => {
    window.location.replace(url);
  }, [url]);
  return null;
};
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <LandingPage />,
    },
    {
      path: "/register",
      element: <ExternalRedirect url="https://app.avefin.com/" />,
    },
    {
      path: "/signin",
      element: <ExternalRedirect url="https://app.avefin.com/" />,
    },
    {
      path: "/ave-analisis-view",
      element: <AnalyticSection />,
    },
    {
      path: "/privacy",
      element: <Privacy />,
    },
    {
      path: "/terms",
      element: <TermsSection />,
    },
  ],
};

export default MainRoutes;
