// src/components/SinusWave.js
import React, { useRef, useEffect } from "react";

const SinusWave = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    const drawSinusWave = (
      phase,
      amplitude,
      frequency,
      gradient,
      lineWidth
    ) => {
      ctx.beginPath();

      const centerY = canvas.height / 1.3;

      for (let x = 0; x < canvas.width; x++) {
        const y = amplitude * Math.sin(frequency * (x + phase)) + centerY;
        ctx.lineTo(x, y);
      }

      ctx.strokeStyle = gradient;
      ctx.lineWidth = lineWidth;
      ctx.stroke();
    };

    // Initialize phase variables for both waves
    let phaseMain = 0;
    let phaseBackground = 0;

    // Define speed for each wave
    const speedMain = 1;
    const speedBackground = 0.8; // Adjust this speed for the background wave

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Create gradients
      const gradientBackground = ctx.createLinearGradient(
        0,
        0,
        canvas.width,
        0
      );
      gradientBackground.addColorStop(0, "rgba(2, 191, 172, 0.8)");
      gradientBackground.addColorStop(1, "rgba(18, 111, 147, 1)");

      const gradientMain = ctx.createLinearGradient(0, 0, canvas.width, 0);
      gradientMain.addColorStop(0, "#01c2a9");
      gradientMain.addColorStop(1, "#1270f6");

      // Draw the background wave with gradient
      drawSinusWave(phaseBackground, 120, 0.003, gradientBackground, 2);

      // Draw the main wave with gradient
      drawSinusWave(phaseMain, 100, 0.005, gradientMain, 2);

      // Increment phase variables separately
      phaseMain += speedMain;
      phaseBackground += speedBackground;

      requestAnimationFrame(animate);
    };

    resizeCanvas();
    animate();
    window.addEventListener("resize", resizeCanvas);

    return () => {
      window.removeEventListener("resize", resizeCanvas);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className="absolute -top-10 left-0 w-full h-full -z-10"
    />
  );
};

export default SinusWave;
