import { useRoutes } from "react-router-dom";

// routes
import MainRoutes from "./MainRoutes";

// ==============================|| ROUTING RENDER ||============================== //

const Router = () => {
  return useRoutes([MainRoutes]);
};

export default Router;
