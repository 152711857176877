import React from "react";
import { useNavigate } from "react-router-dom";
import "./BusinessSection.scss";
import SinusWave from "pages/utils/sinWave";

const BusinessSection = () => {
  const navigate = useNavigate();
  return (
    <div className="relative -mt-[80px]">
      <div className="absolute w-full h-[600px] sm:h-[700px] left-0 top-[-300px] sm:top-[-200px]">
        <SinusWave />
      </div>
      <div className="w-full flex justify-center">
        <div className="relative px-[40px] w-[1040px] -mt-20">
          <div className="bgBackground flex flex-col justify-around relative z-10">
            <div className="text-center leading-[50px]">
              <div className="spaced-text">
                Toma el control <br /> de tu negocio
              </div>
              <div
                className="businessContainer"
                onClick={() => navigate("/register")}
              >
                <div className="business_bt">Registra empresa</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessSection;
