import { jwtDecode } from "jwt-decode";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const CHECKTOKEN = "CHECKTOKEN";
export const SECTION_ID = "SECTION_ID";
export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const decodeToken = (token) => {
  try {
    const decoded = jwtDecode(token);

    return {
      id: decoded.id,
      username: decoded.username,
      email: decoded.email,
      role: decoded.role,
      isVerified: decoded.isVerified,
    };
  } catch (error) {
    console.error("Error decoding token:", error);
    return {};
  }
};
