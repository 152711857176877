import {
  LOGIN,
  LOGOUT,
  decodeToken,
  FETCH_NOTIFICATIONS,
} from "store/action.type";

const initialState = {
  user: localStorage.getItem("token")
    ? { ...decodeToken(localStorage.getItem("token")) }
    : {},
  isAuthenticated: !!localStorage.getItem("token"),
  notifications: 0,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        user: { ...decodeToken(action.payload) },
        isAuthenticated: true,
      };
    case LOGOUT:
      localStorage.removeItem("token");
      return {
        ...state,
        user: {},
        isAuthenticated: false,
      };
    case FETCH_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
