import React, { useState, useEffect } from "react";
import MainHeader from "./HeaderSection/HeaderSection.js";
import MainFooter from "./FoosterSection/FooterSection.js";
import { Outlet } from "react-router-dom";

const MainLayout = () => {
  const [pathName, setPathName] = useState(window.location.pathname);

  useEffect(() => {
    const handleLocationChange = () => {
      setPathName(window.location.pathname);
    };

    // Add event listener for popstate (history changes)
    window.addEventListener("popstate", handleLocationChange);

    // Add event listener for pushState and replaceState (programmatic navigation)
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    window.history.pushState = function (...args) {
      originalPushState.apply(window.history, args);
      handleLocationChange();
    };

    window.history.replaceState = function (...args) {
      originalReplaceState.apply(window.history, args);
      handleLocationChange();
    };

    // Cleanup function
    return () => {
      window.removeEventListener("popstate", handleLocationChange);
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, []);
  return (
    <>
      <MainHeader />
      <div
        className={`w-full h-auto ${pathName == "/signin" ? "" : "mb-[20px]"}  justify-items-center`}
      >
        <Outlet />
      </div>
      <MainFooter />
    </>
  );
};

export default MainLayout;
