import React, { useEffect } from "react";

import "./Terms.scss";

const TermsSection = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div className="w-full flex flex-col items-center text-start">
      <div className="title">Terminos y Condiciones</div>
      <div className="content">
        Esta página web es propiedad y está operado por Ave Márgenes de
        Eficiencia, S.A. de C.V., SOFOM, E.N.R. (en adelante, conjuntamente,
        “Ave”).
      </div>
      <div className="content">
        Esta página permite a los visitantes conocer el giro de Ave, así como
        los servicios que presta. Al acceder o utilizar la página web de nuestro
        servicio, usted aprueba y manifiesta que ha leído, entendido y aceptado
        estar sujeto a estos términos. En consecuencia, al utilizar este sitio
        se entiende que te obligas a cumplir con los Términos y Condiciones de
        su uso, establecidos en el presente documento, por lo que el usuario que
        acceda y/o visite la presente página, manifiesta que reconoce haber
        leído, entendido y estar de acuerdo en sujetarse a sus términos y
        condiciones.
      </div>
      <div className="content">
        Algunos de los servicios de Ave han sido diseñados para que puedas
        almacenar, subir, transferir, enviar, recibir o compartir tu contenido
        y/o información. No tienes obligación alguna de proporcionar contenido a
        nuestros servicios, y puedes elegir libremente el contenido que quieres
        facilitar a Ave. Si decides subir o compartir contenido, asegúrate de
        que tienes los derechos necesarios para ello y de que dicho contenido no
        incumple la ley.
      </div>
      <div className="content">
        El Usuario asume y acepta que Ave podrá conservar copia de la
        información del usuario, y revelar dicha información a terceros si lo
        considera necesario para:
      </div>
      <div className="content">
        Esta página web es propiedad y está operado por Ave Márgenes de
        Eficiencia, S.A. de C.V., SOFOM, E.N.R. (en adelante, conjuntamente,
        “Ave”). Esta página permite a los visitantes conocer el giro de Ave, así
        como los servicios que presta. Al acceder o utilizar la página web de
        nuestro servicio, usted aprueba y manifiesta que ha leído, entendido y
        aceptado estar sujeto a estos términos. En consecuencia, al utilizar
        este sitio se entiende que te obligas a cumplir con los Términos y
        Condiciones de su uso, establecidos en el presente documento, por lo que
        el usuario que acceda y/o visite la presente página, manifiesta que
        reconoce haber leído, entendido y estar de acuerdo en sujetarse a sus
        términos y condiciones. Algunos de los servicios de Ave han sido
        diseñados para que puedas almacenar, subir, transferir, enviar, recibir
        o compartir tu contenido y/o información. No tienes obligación alguna de
        proporcionar contenido a nuestros servicios, y puedes elegir libremente
        el contenido que quieres facilitar a Ave. Si decides subir o compartir
        contenido, asegúrate de que tienes los derechos necesarios para ello y
        de que dicho contenido no incumple la ley. El Usuario asume y acepta que
        Ave podrá conservar copia de la información del usuario, y revelar dicha
        información a terceros si lo considera necesario para: (i) proteger la
        integridad del Sitio Web; (ii) proteger los derechos de Ave; (iii)
        cumplir una orden judicial; (iv) cumplir cualquier trámite legal; (v)
        hacer valer los derechos y acciones que asisten a Ave; y (vi) satisfacer
        cualquier petición relativa a la infracción de derechos de terceros.
      </div>
      <div className="content">
        Los servicios, prestaciones, precios, tarifas y contraprestaciones que
        Ave muestra en la página web están sujetos a cambio sin previo aviso.
      </div>
      <div className="content">
        Ave puede, sin previo aviso, cambiar los servicios; dejar de
        proporcionar los servicios o cualquier característica de los servicios
        que ofrecemos; o bien, sujetar los servicios a cualesquiera condiciones
        y modalidades que Ave estime convenientes. Ave puede suspender, de
        manera permanente o temporal, el acceso a los servicios sin previo aviso
        ni responsabilidad por cualquier motivo, o sin ningún motivo.
      </div>
      <div className="content">
        En este sitio web utilizamos cookies. Las cookies son archivos de texto
        que contienen pequeñas cantidades de información, que se descargan en su
        computadora o dispositivo móvil cuando el usuario visita una página web.
        Ante cada visita nueva a la misma página, los datos almacenados son
        recuperados por el servidor de origen. Las cookies son útiles porque
        permiten que un sitio web reconozca el equipo del usuario.
      </div>
      <div className="content mt-[50px]">
        1. Aceptación del Contrato Términos y Condiciones Generales.
      </div>
      <div className="content">
        Al pulsar el botón "Registrarme" en el Sitio, el usuario admite haber
        revisado, comprendido y aceptado plenamente los Términos y Condiciones
        Generales en su totalidad, comprometiéndose a cumplir con todas sus
        disposiciones y a acatar las leyes y regulaciones correspondientes. La
        activación del botón "Registrarme" establecerá un acuerdo vinculante
        entre las partes. Si el Usuario no está de acuerdo con los Términos y
        Condiciones Generales, se le prohibirá el acceso, la navegación y el uso
        del Sitio, así como la utilización de sus servicios y la información
        proporcionada en la Página Web.
      </div>
      <div className="content mt-[50px]">2. Datos personales.</div>
      <div className="content">
        Para hacer uso de los Servicios de Ave, se requiere completar el
        formulario de inscripción con información personal válida y precisa. El
        Usuario se compromete a mantener actualizados sus datos. Ave puede
        utilizar diversos métodos para verificar la identidad de sus Usuarios y
        no asume responsabilidad por la exactitud de la información
        proporcionada. El acceso a la cuenta se realiza mediante correo
        electrónico y contraseña, con la obligación del Usuario de mantener la
        confidencialidad de su contraseña. Cada Usuario está limitado a una sola
        cuenta Ave, y Ave se reserva el derecho de cancelar cuentas múltiples.
        El Usuario es responsable de todas las acciones realizadas en su cuenta
        y debe informar de cualquier acceso no autorizado de inmediato. Se
        prohíbe la venta, cesión o transferencia de la cuenta Ave. Ave puede
        rechazar o cancelar inscripciones sin necesidad de explicación, sin
        otorgar derecho a indemnización.
      </div>
      <div className="content mt-[50px]">3. Capacidad jurídica.</div>
      <div className="content">
        Los Servicios están dirigidos exclusivamente a individuos con capacidad
        legal para celebrar contratos según las leyes vigentes y que satisfagan
        los criterios especificados en los Términos y Condiciones Generales. Por
        lo tanto, aquellos individuos que carezcan de capacidad legal o hayan
        sido suspendidos temporalmente o inhabilitados permanentemente de la
        plataforma de Ave no podrán acceder a los Servicios. En el caso de que
        el Usuario represente a una persona moral, su representante legal, al
        aceptar los Términos y Condiciones Generales, afirma contar con la
        autoridad necesaria para actuar y celebrar acuerdos en nombre del
        Cliente y/o Usuario, y para comprometerlos con las disposiciones
        establecidas en los Términos y Condiciones Generales.
      </div>
      <div className="content mt-[50px]">4. Indemnización.</div>
      <div className="content">
        El usuario se compromete a indemnizar, defender en pleito y sacar en paz
        y a salvo a Ave de toda responsabilidad, así como a los directivos,
        encargados, factores, dependientes, representantes comerciales y
        empleados de Ave, frente a las reclamaciones de terceros , promovidas
        con base en, o derivadas de, el acceso por parte del usuario al presente
        sitio web, y al uso que este haga del mismo, la información facilitada
        por el usuario, o el incumplimiento por parte de este de los términos y
        condiciones de este contrato.
      </div>

      <div className="content mt-[50px]">5. Propiedad intelectual.</div>
      <div className="content">
        Los servicios que presta Ave, así como todos los materiales incluidos,
        tales como, software, imágenes, gráficos, texto, logotipos, patentes,
        marcas registradas, marcas de servicio, derechos de autor, fotografías,
        audio, videos y todos los Derechos de Propiedad Intelectual relacionados
        con ellos, son la propiedad exclusiva de Ave.
      </div>

      <div className="content mt-[50px]">6. Modificaciones.</div>
      <div className="content">
        Ave se reserva el derecho de modificar los Términos y Condiciones
        Generales en cualquier momento mediante la publicación de los términos
        revisados en el Sitio. Dicha modificaciones entrarán en vigor después de
        20 (veinte) días desde su publicación. En un plazo máximo de 10 (diez)
        días tras la publicación de las modificaciones, el Usuario deberá
        notificar por correo electrónico si no está de acuerdo con los cambios.
        En caso negativo, se dará por terminado el contrato entre las partes y
        el Usuario será deshabilitado del Sitio, a menos que tenga deudas
        pendientes con Ave. Si el plazo vence sin notificación, se entenderá que
        el Usuario acepta los nuevos términos y el acuerdo continuará en vigor
        para ambas partes.
      </div>

      <div className="content mt-[50px]">7. Responsabilidad.</div>
      <div className="content">
        El Cliente y/o Usuario acepta que es completamente responsable del uso
        del Sitio y exime a Ave, así como a sus directores, gerentes, empleados,
        agentes, operarios, representantes y/o funcionarios, de cualquier
        responsabilidad frente a quejas, multas, denuncias, demandas y/o
        acciones por parte de autoridades o terceros debido a un uso negligente
        o inadecuado del Sitio. En caso de que Ave o cualquiera de sus
        directores, gerentes, empleados, agentes, operarios, representantes y/o
        funcionarios deba pagar multas, indemnizaciones u otras sumas de dinero
        debido al uso negligente o inadecuado del Sitio por parte del Usuario,
        este se compromete a reembolsar todas las sumas pagadas y a indemnizar
        cualquier daño ocasionado.
      </div>

      <div className="content mt-[50px]">8. Indemnización.</div>
      <div className="content">
        El Cliente y/o Usuario eximirá de responsabilidad a Ave, así como a sus
        directores, administradores, representantes, funcionarios y empleados,
        frente a cualquier reclamo, demanda, denuncia o acción de terceros
        derivada de las actividades realizadas en el Sitio, el incumplimiento de
        los Términos y Condiciones Generales u otras políticas incorporadas en
        este acuerdo, o la violación de leyes o derechos de terceros. Esta
        obligación de indemnización incluirá los honorarios legales en los que
        incurra Ave por esta causa
      </div>

      <div className="content mt-[50px]">9. Contacto.</div>
      <div className="content">
        El Cliente y/o Usuario enviará cualquier comunicación dirigida a Ave
        mediante correo electrónico a la dirección contacto@avefin.com. Las
        comunicaciones de Ave hacia el Cliente y/o Usuario se realizarán por
        correo electrónico enviado a la dirección electrónica proporcionada por
        este a través del Sitio.
      </div>

      <div className="content mt-[50px]">
        10. Autorización para solicitar información crediticia.
      </div>
      <div className="content">
        Al aceptar los Términos y Condiciones Generales, el Usuario y/o Cliente
        declara bajo juramento que la información entregada a través del Sitio
        es verdadera, por lo que en caso contrario la misma será rechazada.
      </div>
      <div className="content">
        Asimismo, al aceptar los Términos y Condiciones Generales, el Usuario
        y/o Cliente otorga a Ave la autorización para llevar a cabo análisis y
        evaluaciones sobre su historial crediticio mediante los canales
        adecuados. El Usuario reconoce comprender la naturaleza y el alcance de
        la información solicitada por Ave a estas instituciones.
      </div>
      <div className="content">
        El Usuario y/o Cliente está plenamente consciente y acepta de manera
        explícita que dicho archivo electrónico y/o medio electrónico y/o
        mensaje de datos pertenecerá a Ave y/o a cualquier entidad encargada de
        mantener registros o bancos de datos personales a los que Ave recurra
        para obtener información crediticia, con el fin de cumplir con sus
        obligaciones y llevar a cabo un adecuado control.
      </div>

      <div className="content mt-[50px]">
        11. Ley aplicable de jurisdicción.
      </div>
      <div className="content mb-[100px]">
        El presente aviso se regirá por las disposiciones legales aplicables en
        la República Mexicana, en especial, por lo dispuesto en la Ley Federal
        de Protección de Datos Personales en Posesión de los Particulares, su
        reglamento y la demás normativa vinculada aplicable.
      </div>
    </div>
  );
};

export default TermsSection;
