import HeroSection from "./Hero/HeroSection";
import PricingSection from "./Pricing/PricingSection";
import AccordinSection from "./Accordin/AccordinSection";
import Carousel from "./Carousel/Carousel";
import BusinessSection from "./BusinessSection/BusinessSection";
import { SECTION_ID } from "store/action.type";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

const MainSection = () => {
  const sectionId = useSelector((s) => s.landing.sectionId);
  const dispatch = useDispatch();
  useEffect(() => {
    if (sectionId) {
      const section = document.getElementById(sectionId);

      if (section) {
        const headerOffset = 120; // Adjust this value according to your needs
        const elementPosition =
          section.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
      dispatch({ type: SECTION_ID, payload: null });
    }
  }, [sectionId, dispatch]);

  return (
    <div className="w-full">
      <div className="w-full flex flex-col items-center justify-center">
        <div className="w-full xl:flex xl:justify-center" id="hero">
          <HeroSection />
        </div>
        <div className="w-full" id="pricing">
          <PricingSection />
        </div>
        <div className="w-full mt-[70px]" id="business">
          <BusinessSection />
          <Carousel />
        </div>
        <div
          className="w-full xl:flex xl:justify-center mt-[70px]"
          id="accordion"
        >
          <AccordinSection />
        </div>
      </div>
    </div>
  );
};

export default MainSection;
