import { SECTION_ID } from "store/action.type";

const initialState = {
  sectionId: null,
};

const landingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SECTION_ID:
      return {
        ...state,
        sectionId: action.payload,
      };
    default:
      return state;
  }
};

export default landingReducer;
