import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SECTION_ID } from "store/action.type";
import "./HeaderSection.style.scss";
import logo from "assets/images/AVE_logo.svg";
import item_1 from "assets/images/appbar_assets/back_to_back.png";
import item_2 from "assets/images/appbar_assets/docs_dropdown.png";
import item_3 from "assets/images/appbar_assets/rocket_icon_credito.png";
import item_4 from "assets/images/hamburguesa.svg";
import cancel_white from "assets/images/tache_white.svg";
import logo_white from "assets/images/AVE_logo_white.svg";

const HeaderSection = () => {
  const [drop, setDrop] = useState(false);
  const [menubar, setMenuBar] = useState(false);
  const navigate = useNavigate();
  const pathName = window.location.pathname;
  const dispatch = useDispatch();
  const menuRef = useRef(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setDrop(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const scrollToSection = (sectionId) => {
    console.log(sectionId, "=================sectionId==========");
    dispatch({ type: SECTION_ID, payload: sectionId });
  };
  return (
    <>
      <div
        className={`HeaderContainer xl:justify-items-center xl:${pathName == "/register" ? "" : "justify-center"} ${pathName == "/register" ? "flex h-[80px] justify-start px-[45px]" : ""} ${pathName == "/signin" ? "hidden" : ""}`}
      >
        <div className="w-full flex justify-between px-[45px] xl:w-[1388px] 2xl:px-[0px] items-center ">
          <div onClick={() => { navigate("/"); scrollToSection("hero"); }} className="cursor-pointer">
            <img
              src={logo}
              alt="AVE"
              className="h-[40px] object-contain select-none pointer-events-auto"
              draggable="false"
            />
          </div>
          <div>
            <img
              src={item_4}
              width="40"
              onClick={() => setMenuBar(!menubar)}
              className={`xl:hidden cursor-pointer ${pathName == "/register" ? "hidden" : ""}`}
            />

            <div
              className={`menuBarBg top-0 right-0 absolute h-screen duration-300
              transition-transform ${menubar ? "translate-x-0" : "-translate-x-full"}`}
            >
              <div onClick={() => {
                setMenuBar(!menubar);
                navigate("/");
                scrollToSection("hero");
              }} className="w-full h-[120px] flex justify-between items-center px-[45px] ">
                <img src={logo_white} className="h-[40px]" />
                <img
                  src={cancel_white}
                  className="h-[40px] cursor-pointer"
                  onClick={() => setMenuBar(!menubar)}
                />
              </div>
              <div className="menuBarFont py-[113px] h-[60%] flex flex-col gap-y-[30px] justify-between">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setMenuBar(!menubar);
                    navigate("/");
                    scrollToSection("hero");
                  }}
                >
                  Inicio
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setMenuBar(!menubar);
                    navigate("/");
                    scrollToSection("pricing");
                  }}
                >
                  Financiamiento AVE
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setMenuBar(!menubar);
                    navigate("/ave-analisis-view");
                  }}
                >
                  Ave Analytics
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setMenuBar(!menubar);
                    navigate("/");
                    scrollToSection("accordion");
                  }}
                >
                  FAQ
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setMenuBar(!menubar);
                    navigate("/signin");
                  }}
                >
                  Ingresar
                </div>
              </div>
              <div className="w-[20%] 2xl:block">
                {
                  <div className="registerContainerMobile">
                    <div
                      onClick={() => {
                        setMenuBar(!menubar);
                        navigate("/register");
                      }}
                      className="registerButton_1"
                    >
                      Registra a tu empresa
                    </div>
                  </div>
                }
              </div>
            </div>
            {pathName !== "/register" && (
              <div className="items-center hidden xl:flex">
                <div className="flex ">
                  <div
                    className="h-[33px] navLink"
                    onClick={() => {
                      navigate("/");
                      scrollToSection("hero");
                    }}
                  >
                    Inicio
                  </div>
                  <div
                    className="h-[33px] navLink flex"
                    onClick={() => setDrop(!drop)}
                  >
                    Productos
                    <svg
                      class="-mr-1 mt-1 ml-1 h-5 w-5 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                      data-slot="icon"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <div
                      className="absolute"
                      onClick={() => {
                        navigate("/");
                        scrollToSection("pricing");
                      }}
                    >
                      {drop == true ? (
                        <div
                          ref={menuRef}
                          className="drop_div mt-7 w-[426px] h-[371px] justify-center"
                        >
                          <div className="drop_div_item w-full h-[33%] flex pr-[15px]">
                            <div className="w-[25%] flex">
                              <img src={item_3} width="60" className="m-auto" />
                            </div>
                            <div className="w-[75%] flex flex-col m-auto">
                              <div className="dropItemHeader text-left">
                                Crédito Simple
                              </div>
                              <div className="dropItemBody text-left">
                                Producto de financiamiento que otorga la
                                liquidez necesaria para que los negocios
                                alcancen sus objetivos.
                              </div>
                            </div>
                          </div>
                          <div className="drop_div_item w-full h-[33%] flex pr-[15px]">
                            <div className="w-[25%] flex">
                              <img src={item_2} width="60" className="m-auto" />
                            </div>
                            <div className="w-[75%] lex flex-col m-auto">
                              <div className="dropItemHeader">
                                Factoraje financiero
                              </div>
                              <div className="dropItemBody">
                                Fuente de financiamiento a corto plazo que
                                proporciona liquidez a los negocios a cambio de
                                sus cuentas por cobrar.
                              </div>
                            </div>
                          </div>
                          <div className="drop_div_item w-full h-[33%] flex pr-[15px]">
                            <div className="w-[25%] flex">
                              <img src={item_1} width="60" className="m-auto" />
                            </div>
                            <div className="w-[75%] lex flex-col m-auto">
                              <div className="dropItemHeader">Back to Back</div>
                              <div className="dropItemBody">
                                Línea de crédito flexible respaldada por una
                                cuenta de inversión que otorga liquidéz inmediata
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div
                    className="h-[33px] navLink"
                    onClick={() => {
                      navigate("/ave-analisis-view");
                    }}
                  >
                    Ave Analytics
                  </div>
                  <div
                    className="h-[33px] navLink"
                    onClick={() => {
                      navigate("/");
                      scrollToSection("accordion");
                    }}
                  >
                    FAQ
                  </div>
                  <div
                    className="h-[33px] navLink"
                    onClick={() => {
                      navigate("/signin");
                    }}
                  >
                    Ingresar
                  </div>
                </div>
                <div className="registerContainer cursor-pointer">
                  <div
                    onClick={() => navigate("/register")}
                    className="registerButton"
                  >
                    Registra a tu empresa
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderSection;
