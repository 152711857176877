import React, { useEffect } from "react";
import "./Privacidad.scss";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div className="w-full flex flex-col items-center text-start">
      <div className="title">Aviso de Privacidad</div>
      <div className="content">
        Esta página web es propiedad y está operado por Ave Márgenes de
        Eficiencia, S.A. de C.V., SOFOM, E.N.R. (en adelante, conjuntamente,
        “Ave”).
      </div>
      <div className="content">
        Ave se reserva el derecho a realizar modificaciones o actualizaciones a
        este aviso de privacidad en cualquier momento, sin necesidad de previo
        aviso. Estas modificaciones estarán disponibles para el público,
        mediante el sitio web https://avefin.com, sección “aviso de privacidad”.
      </div>
      <div className="content">
        En este sitio web utilizamos cookies. Las cookies son archivos de texto
        que contienen pequeñas cantidades de información, que se descargan en su
        computadora o dispositivo móvil cuando el usuario visita una página web.
        Ante cada visita nueva a la misma página, los datos almacenados son
        recuperados por el servidor de origen. Las cookies son útiles porque
        permiten que un sitio web reconozca el equipo del usuario.
      </div>
      <div className="content">1. Responsable.</div>
      <div className="content">
        En cumplimiento de la Ley Federal de Protección de Datos Personales en
        Posesión de Particulares, Ave le informa que es responsable de sus datos
        personales. El usuario puede comunicarse con Ave en cualquier momento a
        través de nuestro correo electrónico contacto@avefin.com o directamente
        en nuestras oficinas ubicadas en Alica 49, Piso 2, Molino del Rey,
        Miguel Hidalgo, 11040, CDMX, México. y nuestro número telefónico es 55
        5520 8744.
      </div>
      <div className="content">
        Resguardamos y protegemos sus datos personales para evitar daños,
        pérdidas, destrucción, robo, alteración, así como el tratamiento no
        autorizado de sus datos personales.
      </div>
      <div className="content  mt-[50px]">2. Información personal.</div>
      <div className="content">
        La información que proporcione usted a Ave debe ser completa y veraz. El
        usuario responderá siempre de los datos facilitados y en ningún caso Ave
        será responsable de la veracidad de los mismos.
      </div>
      <div className="content">
        La información solicitada al usuario puede ser, entre otra que le
        requiera Ave, la siguiente:
        <ul className="ml-[10px]">
          <li>• Nombre completo</li>
          <li>• Clave Única de Registro de Población (C.U.R.P.)</li>
          <li>• Edad</li>
          <li>• Lugar de nacimiento</li>
          <li>• Fecha de nacimiento</li>
          <li>• Domicilio</li>
          <li>• Correo electrónico</li>
          <li>• Número telefónico</li>
          <li>• Datos de facturación</li>
        </ul>
      </div>
      <div className="content">
        Sus datos personales serán tratados de conformidad, y en cumplimiento, a
        los principios de licitud, consentimiento, calidad, información,
        finalidad, lealtad, responsabilidad y proporcionalidad en términos de la
        ley aplicable.
      </div>
      <div className="content">
        La confidencialidad de sus datos personales se mantendrá mediante el
        establecimiento y mantenimiento de medidas de seguridad administrativas,
        para evitar su daño, alteración, pérdida, uso indebido, destrucción, o
        divulgación.
      </div>
      <div className="content mt-[50px]">3. Uso de la información.</div>
      <div className="content">
        Ave utilizará para los siguientes fines:
        <ul className="ml-[10px]">
          <li>
            • Promover los productos, servicios e información que considere
            oportunos
          </li>
          <li>
            • Proporcionar los productos, servicios o información que considere
            oportunos
          </li>
          <li>
            • Informar sobre cambios, nuevos servicios y/o productos que se
            relacionen con lo contratado o adquirido por el cliente
          </li>
          <li>• Para cumplir con las obligaciones con sus clientes</li>
          <li>• Para evaluar la calidad del servicio</li>
          <li>
            • Para dar seguimiento a pedidos o servicios hechos por Internet
          </li>
          <li>
            • Realizar investigaciones sobre los hábitos de los consumidores
          </li>
        </ul>
      </div>
      <div className="content">
        Los datos personales que nos facilite el usuario formarán parte de un
        clasificador digital que contendrá su perfil. El usuario está en aptitud
        modificar su perfil en cualquier momento utilizando su número de usuario
        y contraseña.
      </div>
      <div className="content mt-[50px]">
        4. Limitación de uso de la información.
      </div>

      <div className="content">
        Solo Ave tiene acceso a la información recopilada. Ave se compromete a
        no ceder su información personal a terceros, salvo las excepciones
        previstas en el artículo 37 de la Ley Federal de Protección de Datos
        Personales en Posesión de los Particulares, así como a realizar dicha
        transferencia en los términos legales aplicables.
      </div>
      <div className="content mt-[50px]">
        5. Derechos ARCO (acceso, rectificación, cancelación y oposición).
      </div>

      <div className="content">
        El área responsable del manejo y administración de los datos personales
        que obran en poder de Ave es la Unidad de Atención al Cliente; área que
        puede ser contactada a través del correo electrónico
        contacto@avefin.com, o directamente en nuestras oficinas ubicadas en
        Alica 49, Piso 2, Molino del Rey, Miguel Hidalgo, 11040, CDMX, México.,
        o bien, mediante nuestro número telefónico 55 5520 8744.
      </div>
      <div className="content">
        Tienes derecho a (i) acceder a tus datos personales de los que dispone
        Ave, así como al detalle del tratamiento de los mismos; (ii) a
        rectificar tus datos personales si son inexactos o incompletos; (iii) a
        solicitar que se den de baja del archivo de Ave cuando consideres que no
        son necesarios para alguna de las finalidades indicadas en este aviso de
        privacidad, estén siendo utilizados con fines no consentidos o hayan
        terminado la relación contractual o de servicio; (iv) a oponerte al
        tratamiento de los mismos para fines específicos; o (v) revocar el
        consentimiento que otorgaste a Ave para el tratamiento de tus datos
        personales, para que dejemos de utilizarlos.
      </div>

      <div className="content">
        Podrás ejercer tus derechos ARCO mediante la solicitud que realices
        mediante correo electrónico enviado a la cuenta: contacto@avefin.com.
      </div>
      <div className="content">
        Tu solicitud debe contener, al menos, la siguiente información: (i)
        nombre completo y dirección de correo electrónico, o domicilio físico
        para comunicar la respuesta a su solicitud; (ii) documentos que
        acrediten tu identidad o, en su caso, representación legal; (iii) la
        descripción pormenorizada de los datos personales respecto de los cuales
        buscas ejercer alguno de los derechos ARCO; y (iv) cualquier otro
        elemento o documento que facilite a Ave la localización de datos
        personales.
      </div>

      <div className="content">
        En términos de lo previsto por la Ley Federal de Protección de Datos
        Personales en Posesión de las Personas Físicas, Ave cuenta con un plazo
        de 20 (veinte) días hábiles, contados a partir de la fecha en que Ave
        recibió la solicitud, para comunicar la decisión adoptada y, en su caso,
        en su caso. En caso de que la circunstancia lo justifique, Ave podrá
        gozar de una prórroga por otro plazo de 20 (veinte) días hábiles. La
        obligación de acceso a la información se considerará cumplida cuando los
        datos personales estén disponibles a través de copias simples o
        documentos electrónicos.
      </div>
      <div className="content">
        Si el usuario considera que se han afectado o violentado sus derechos en
        materia de protección de datos personales, tiene derecho a acudir a la
        autoridad correspondiente para defender sus derechos. La autoridad es el
        Instituto Nacional de Transparencia, Acceso a la Información y
        Protección de Datos Personales (INAI).
      </div>

      <div className="content mt-[50px]">6. Consentimiento</div>
      <div className="content">
        Al utilizar nuestro sitio web, y/o al contratar los servicios que
        ofrecemos se entenderá que nos ha otorgado su consentimiento.
      </div>

      <div className="content mt-[50px]">7. Ley aplicable de jurisdicción.</div>
      <div className="content">
        El presente aviso se regirá por las disposiciones legales aplicables en
        la República Mexicana, en especial, por lo dispuesto en la Ley Federal
        de Protección de Datos Personales en Posesión de los Particulares, su
        reglamento y la demás normativa vinculada aplicable.
      </div>
      <div className="content mt-[50px]">8. Última actualización</div>
      <div className="content mb-[100px]">
        El presente aviso de privacidad se encuentra vigente desde el día 21 de
        febrero de 2024, en que se publicó en esta página web su última
        actualización.
      </div>
    </div>
  );
};

export default Privacy;
