import React from "react";
import { useNavigate } from "react-router-dom";
import ChicaImage from "assets/images/chica_pantone.jpg";
import WeyTrabajando from "assets/images/wey_trabajando.jpg";
import CafeteriaImage from "assets/images/cafeteria.png";
import OpenOfficeImage from "assets/images/open_office.jpg";
import MockPlatformImage from "assets/images/mock_plataforma.jpg";
import LapizImage from "assets/images/lapiz_3d.png";
import TachuelaImage from "assets/images/tachuela_3d.png";
import MonedaImage from "assets/images/moneda_3d.png";
import RightArrowImage from "assets/images/arrow_right_gradient.png";
import DownArrowImage from "assets/images/arrow_down_gradient.png";
import LogoImage from "assets/images/AVE_logo.svg";
import SinusWave from "pages/utils/sinWave";

import "./HeroSection.scss";

const HeroSection = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full px-[30px] sm:px-[60px] xl:px-[0px] xl:w-[1040px] xl:mt-[40px]">
      <SinusWave />
      <div className="w-full xl:flex justify-between ">
        <div className="">
          <div className="topicText text-start mt-[0px] xl:mt-[24px]">
            La plataforma que
          </div>
          <div className="topicText text-start mt-[12px]">
            impulsará tu negocio
          </div>
          <div className="detailText text-start xl:w-[470px] mt-[40px]">
            Obtén hasta $5 millones en menos de 72 horas y accede a las
            herramientas financieras que potenciarán tu crecimiento.
          </div>
        </div>

        <div className="flex xl:hidden justify-center sm:justify-start mt-[30px] mb-[20px] sm:mb-[0px]">
          <div onClick={() => navigate("/register")} className="startBtn">
            ¡Comenzar ahora!
          </div>
        </div>

        <div className="	overflow-hidden w-full justify-center sm:justify-end xl:w-auto flex gap-[15px] xl:gap-[20px] h-[200px] sm:h-[250px] xl:h-[300px] mt-[30px]">
          <div className="h-full flex items-end">
            <div className="weyTrabajando">
              <img
                src={WeyTrabajando}
                alt=""
                className="w-full h-full object-cover"
              />
            </div>
          </div>

          <div className="chicaImage">
            <img
              src={ChicaImage}
              alt=""
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
      <div className="relative w-full flex justify-center sm:justify-end xl:justify-between mt-[10px] sm:mt-[20px]">
        <div
          className="startBtn hidden xl:flex"
          onClick={() => navigate("/register")}
        >
          ¡Comenzar ahora!
        </div>
        <div className="cafeteriaImage">
          <img src={CafeteriaImage} alt="" />
        </div>
        <div className="hidden sm:block absolute left-[20%] top-[-30px] xl:left-[180px] xl:top-[180px]">
          <div className="businessTip">
            <div className="w-full flex justify-between">
              <div className="flex gap-[6px]">
                <img src={LogoImage} alt="" className="w-[40px] h-[14px]" />
                <div className="businessTitle">Negocios</div>
              </div>
              <div>
                <div className="businessTitle">ahora</div>
              </div>
            </div>
            <div className="businessText mt-[10px]">
              ¡Felicidades, tu crédito fue&nbsp;
              <span className="businessGreen">aprobado</span>!
            </div>
          </div>
        </div>
      </div>

      <div className="middleText mt-[40px] xl:mt-[80px] text-center">
        Empoderamos a las pequeñas y medianas empresas de México con las{" "}
        <span className="greenText">soluciones financieras</span> que se{" "}
        <span className="greenText">merecen</span>
      </div>

      <div className="hidden xl:block relative w-full mt-[55px] xl:h-[585px] h-[320px]">
        <div className="absolute openOfficeImage left-[100px] top-[0px]">
          <img src={OpenOfficeImage} alt="" />
        </div>
        <div className="absolute openOfficeImage left-[180px] top-[70px]">
          <img src={MockPlatformImage} alt="" />
        </div>

        <div className="absolute left-[50px] top-[370px]">
          <div className="businessTip w-[300px] h-[120px]">
            <div className="w-full flex justify-between">
              <div className="flex gap-[6px]">
                <img src={LogoImage} alt="" className="w-[40px] h-[14px]" />
                <div className="businessTitle">Negocios</div>
              </div>
              <div>
                <div className="businessTitle">ahora</div>
              </div>
            </div>
            <div className="obtainText mt-[8px] text-start">
              <span className="obtainBlue">Acceso gratuito </span>a Ave
              Analytics, herramienta de salud financiera.
            </div>
          </div>
        </div>

        <div className="absolute right-[20px] top-[300px]">
          <div className="obtainTip w-[252px] h-[132px]">
            <div className="w-full flex justify-between">
              <div className="flex gap-[6px]">
                <img src={LogoImage} alt="" className="w-[40px] h-[14px]" />
                <div className="businessTitle">Negocios</div>
              </div>
              <div>
                <div className="businessTitle">ahora</div>
              </div>
            </div>
            <div className="obtainText mt-[8px] text-start">
              Obtén la liquidez que tu negocio necesita en&nbsp;
              <span className="obtainBlue">menos de 72 horas.</span>
            </div>
          </div>
        </div>
      </div>

      <div className="xl:hidden block">
        <div className="w-full flex justify-center mt-[30px] sm:mt-[55px]">
          <div className="relative w-[320px] sm:w-[460px] h-[250px] sm:h-[320px]">
            <div className="absolute openOfficeImage left-[0px] top-[0px]">
              <img src={OpenOfficeImage} alt="" />
            </div>
            <div className="absolute openOfficeImage left-[30px] top-[30px]">
              <img src={MockPlatformImage} alt="" />
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center mt-[30px] sm:mt-[60px]">
          <div className="businessTip w-[300px] h-[120px]">
            <div className="w-full flex justify-between">
              <div className="flex gap-[6px]">
                <img src={LogoImage} alt="" className="w-[40px] h-[14px]" />
                <div className="businessTitle">Negocios</div>
              </div>
              <div>
                <div className="businessTitle">ahora</div>
              </div>
            </div>
            <div className="obtainText mt-[8px] text-start">
              <span className="obtainBlue">Acceso gratuito </span>a Ave
              Analytics, herramienta de salud financiera.
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center mt-[30px]">
          <div className="obtainTip w-[252px] h-[132px]">
            <div className="w-full flex justify-between">
              <div className="flex gap-[6px]">
                <img src={LogoImage} alt="" className="w-[40px] h-[14px]" />
                <div className="businessTitle">Negocios</div>
              </div>
              <div>
                <div className="businessTitle">ahora</div>
              </div>
            </div>
            <div className="obtainText mt-[8px] text-start">
              Obtén la liquidez que tu negocio necesita en&nbsp;
              <span className="obtainBlue">menos de 72 horas.</span>
            </div>
          </div>
        </div>
      </div>

      <div className="middleText relative w-full mt-[50px] xl:mt-[150px] flex justify-center">
        Proporcionamos los mejores beneficios con requisitos mínimos
      </div>

      <div className="w-full mt-[70px] hidden sm:flex justify-center">
        <div className="flex flex-col justify-center items-center">
          <img
            src={LapizImage}
            alt=""
            className="w-[30px] h-[50px] xl:w-[50px] xl:h-[84px] object-cover"
          />
          <div className="benefitTitle mt-[25px]">Regístrate</div>
          <div className="benefitDesc mt-[20px]">
            Completa nuestros requerimientos y abre tu cuenta en menos de un
            minuto.
          </div>
        </div>
        <div className="xl:ml-[50px] mt-[60px]">
          <img
            src={RightArrowImage}
            alt=""
            className="w-[150px] h-[10px] xl:h-[12px] object-cover object-right"
          />
        </div>
        <div className="flex flex-col justify-center items-center">
          <img
            src={TachuelaImage}
            alt=""
            className="w-[45px] h-[50px] xl:w-[72px] xl:h-[84px] object-cover"
          />
          <div className="benefitTitle mt-[25px]">Selecciona</div>
          <div className="benefitDesc mt-[20px]">
            Elige el producto de financiamento que mejor se adapte a tus
            necesidades.
          </div>
        </div>
        <div className="xl:mr-[50px] mt-[60px] flex justify-end">
          <img
            src={RightArrowImage}
            alt=""
            className="w-[150px] h-[10px] xl:h-[12px] object-cover object-right"
          />
        </div>
        <div className="flex flex-col justify-center items-center">
          <img
            src={MonedaImage}
            alt=""
            className="w-[50px] h-[50px] xl:w-[84px] xl:h-[84px] object-cover"
          />
          <div className="benefitTitle mt-[25px]">Crece</div>
          <div className="benefitDesc mt-[20px]">
            Obtén el respaldo financero que tu negocio merece para continuar
            creciendo
          </div>
        </div>
      </div>

      <div className="sm:hidden w-full flex flex-col items-center mt-[40px]">
        <img
          src={LapizImage}
          alt=""
          className="w-[50px] h-[84px] sm:w-[30px] sm:h-[50px] xl:w-[50px] xl:h-[84px] object-cover"
        />
        <div className="benefitTitle mt-[20px]">Regístrate</div>
        <div className="benefitDesc mt-[20px]">
          Completa nuestros requerimientos y abre tu cuenta en menos de un
          minuto.
        </div>

        <div className="py-[15px]">
          <img
            src={DownArrowImage}
            alt=""
            className="w-[14px] h-[44px] object-contain"
          />
        </div>

        <img
          src={TachuelaImage}
          alt=""
          className="w-[72px] h-[84px] sm:w-[45px] sm:h-[50px] xl:w-[72px] xl:h-[84px] object-cover"
        />
        <div className="benefitTitle mt-[20px]">Selecciona</div>
        <div className="benefitDesc mt-[20px]">
          Elige el producto de financiamento que mejor se adapte a tus
          necesidades.
        </div>

        <div className="py-[15px]">
          <img
            src={DownArrowImage}
            alt=""
            className="w-[14px] h-[44px] object-contain"
          />
        </div>

        <img
          src={MonedaImage}
          alt=""
          className="w-[84px] h-[84px] sm:w-[50px] sm:h-[50px] xl:w-[84px] xl:h-[84px] object-cover"
        />
        <div className="benefitTitle mt-[25px]">Crece</div>
        <div className="benefitDesc mt-[20px]">
          Obtén el respaldo financero que tu negocio merece para continuar
          creciendo
        </div>
      </div>
      <div className="w-full flex justify-center mt-[80px] mb-[70px]">
        <div onClick={() => navigate("/register")} className="startBtn">
          Abre tu cuenta
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
