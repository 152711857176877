import React, { useEffect, useState } from "react";

// third party components
import { Range as ReactRange, getTrackBackground } from "react-range";

// styles
import "./AveRange.scss";

const AveRange = (props) => {
  const { min, max, step, value, onChange } = props;

  const [values, setValues] = useState([min]);

  useEffect(() => {
    let array = [];
    array.push(value);
    setValues(array);
  }, [value]);

  return (
    <div className="container">
      <ReactRange
        values={values}
        step={step}
        min={min}
        max={max}
        onChange={(values) => {
          onChange(values[0]);
        }}
        renderTrack={({ props, children }) => (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "5px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values,
                  colors: [
                    "var(--brand-01-color)",
                    "var(--brand-02-color)",
                    "var(--brand-02-color)",
                  ],
                  min: min,
                  max: max,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => <div {...props} className="thumb" />}
      />
    </div>
  );
};

export default AveRange;
